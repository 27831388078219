// components/UsernameForm.js
import React, { useState } from 'react';
import './UsernameForm.css';

function UsernameForm({ onSubmit, onHistoryClick }) {
  const [username, setUsername] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username.trim()) {
      onSubmit(username.trim());
    }
  };

  return (
    <div className="login-container fixed-center">
      <div className="login-form">
        <div className="form-header">
          <h1>$KAI THE COIN OF THE COMMUNITY</h1>
          <p className="subtitle">Enter your username to begin</p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <div className="form-buttons">
            <button type="button" onClick={onHistoryClick} className="cyber-button">
              Resources
            </button>
            <button type="submit" className="cyber-button">
              Enter Chat
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UsernameForm;