// frontend/src/components/TweetDisplay.js
import React from 'react';
import './TweetDisplay.css';

function TweetDisplay({ tweets }) {
  if (tweets.length === 0) return null;

  return (
    <div className="tweet-display">
      <h3>Kizuna's Diary</h3>
      <p id='undertext'>How it works: Kizuna watches the chat and curates an AI generated diary post which automatically gets posted to X.</p>
      <div className="tweets-list">
        {tweets.map((tweet, index) => (
          <div key={tweet.timestamp || index} className="tweet">
            <p>{tweet.content}</p>
            <small>{new Date(tweet.timestamp).toLocaleTimeString()}</small>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TweetDisplay;