import React, { useEffect, useRef } from 'react';
import './Chatbox.css';

// Color utilities
const userColors = {
  colors: [
    '#FF6B6B', // Coral
    '#4ECDC4', // Turquoise
    '#45B7D1', // Sky Blue
    '#96CEB4', // Mint
    '#D4A5A5', // Rose
    '#9B59B6', // Purple
    '#3498DB', // Blue
    '#E67E22', // Orange
    '#1ABC9C', // Emerald
    '#FF69B4', // Pink
  ],
  colorMap: new Map(),

  getColorForUser(username) {
    if (!this.colorMap.has(username)) {
      const randomColor = this.colors[Math.floor(Math.random() * this.colors.length)];
      this.colorMap.set(username, randomColor);
    }
    return this.colorMap.get(username);
  }
};

function Chatbox({ messages, currentUser }) {
  const messagesEndRef = useRef(null);
  const chatboxRef = useRef(null);

  const scrollToBottom = () => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="chatbox" ref={chatboxRef}>
      <div className="messages-container">
        {messages.map((msg, index) => (
          <div
            key={msg.id || index}
            className="message"
          >
            <div 
              className="username"
              style={{ color: userColors.getColorForUser(msg.user) }}
            >
              {msg.user}
            </div>
            <div className="message-text">{msg.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Chatbox;