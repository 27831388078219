import React from 'react';
import './History.css';

function History({ onBack }) {
  return (
    <div className="history-container">
      <button onClick={onBack} className="cyber-button back-button">
        ← Back
      </button>
      <h1 className="history-title">Resources</h1>
      <div className="history-buttons">
        <a
          href="https://en.wikipedia.org/wiki/Kizuna_AI"
          target="_blank"
          rel="noopener noreferrer"
          className="cyber-button resource-button"
        >
          WIKIPEDIA
        </a>
        <a
          href="https://virtualyoutuber.fandom.com/wiki/Kizuna_Ai"
          target="_blank"
          rel="noopener noreferrer"
          className="cyber-button resource-button"
        >
          FANDOM
        </a>
      </div>
    </div>
  );
}

export default History;
