// frontend/src/components/Chat.js
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import Chatbox from './Chatbox';
import InputBox from './InputBox';
import TweetDisplay from './TweetDisplay';
import './Chat.css';

let socket;

function Chat({ username, onBack }) {
  const [messages, setMessages] = useState([]);
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    // Initialize socket connection
    socket = io(process.env.REACT_APP_SOCKET_URL || 'http://localhost:3001', {
      transports: ['websocket'],
    });

    // Set up socket event listeners
    socket.on('chat history', (history) => {
      setMessages(history);
    });

    socket.on('chat message', (message) => {
      setMessages(prev => [...prev, message]);
    });

    socket.on('delete_message', (messageId) => {
      setMessages(prev => prev.filter(msg => msg.id !== messageId));
    });

    socket.on('new_tweet', (tweet) => {
      setTweets(prev => [tweet, ...prev].slice(0, 5));
    });

    // Cleanup on unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const handleSendMessage = (text) => {
    if (!socket?.connected) {
      console.log('Socket not connected, attempting to reconnect...');
      socket?.connect();
      setTimeout(() => handleSendMessage(text), 1000);
      return;
    }

    socket.emit('chat message', { user: username, text });
  };

  return (
    <div className="chat-page">
      <button className="back-button" onClick={onBack}>
        ← Back
      </button>
      <div className="chat-layout">
        <div className="chat-main">
          <Chatbox messages={messages} currentUser={username} />
          <InputBox onSendMessage={handleSendMessage} />
        </div>
        <TweetDisplay tweets={tweets} />
      </div>
    </div>
  );
}

export default Chat;
