// App.js
import React, { useState } from 'react';
import UsernameForm from './components/UsernameForm';
import Chat from './components/Chat';
import History from './components/History';
import './App.css';

function App() {
  const [username, setUsername] = useState('');
  const [currentView, setCurrentView] = useState('welcome');

  const handleLogin = (name) => {
    setUsername(name);
    setCurrentView('chat');
  };

  const handleHistoryClick = () => {
    setCurrentView('history');
  };

  const handleBack = () => {
    setCurrentView('welcome');
  };

  return (
    <div className="app-container">
      <div className="global-nav">
        <a 
          href="https://pump.fun" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="cyber-button"
        >
          PUMP.FUN
        </a>
        <a 
          href="https://x.com/kizunaaicoin" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="cyber-button"
        >
          TWITTER
        </a>
      </div>

      <div className="content-wrapper">
        <div className={`section welcome-section ${currentView === 'welcome' ? 'active' : ''}`}>
          <UsernameForm 
            onSubmit={handleLogin}
            onHistoryClick={handleHistoryClick}
          />
        </div>

        <div className={`section chat-section ${currentView === 'chat' ? 'active' : ''}`}>
          <Chat username={username} onBack={handleBack} />
        </div>

        <div className={`section history-section ${currentView === 'history' ? 'active' : ''}`}>
          <History onBack={handleBack} />
        </div>
      </div>
    </div>
  );
}

export default App;